<tui-hosted-dropdown class="h-full min-h-[56px] w-full" tuiDropdownLimitWidth="fixed" [content]="dropdown" [(open)]="isOpen">
  <div
    class="relative inline-block text-left w-full h-full rounded-md {{ showBorder ? 'border' : 'border-none' }} border-brand-gray-200 {{
      showShadow ? 'shadow-sm' : 'shadow-none'
    }} overflow-hidden">
    <button
      type="button"
      class="inline-flex h-full w-full items-center justify-between gap-2 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none [&>*]:pointer-events-none"
      aria-expanded="true"
      aria-haspopup="true">
      <ng-content select="[content]"></ng-content>
      <svg class="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
        <path
          fill-rule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
          clip-rule="evenodd" />
      </svg>
    </button>
  </div>
</tui-hosted-dropdown>

<ng-template #dropdown [tuiActiveZoneParent]="tuiActiveZone">
  <ng-content (click)="isOpen = false"></ng-content>
</ng-template>
