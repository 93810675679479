import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TuiActiveZoneDirective } from '@taiga-ui/cdk';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent implements OnInit {
  @ViewChild('content') public innerContent: TemplateRef<any>;

  @Input() public showShadow = true;
  @Input() public showBorder = true;
  @Input() public tuiActiveZone: TuiActiveZoneDirective | null = null;

  public isOpen = false;

  constructor() {}

  public ngOnInit(): void {}

  public closePopover(): void {
    this.isOpen = false;
  }
}
